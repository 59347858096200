import "./components/player";
import 'lazysizes';
import 'parsleyjs';
import Alpine from 'alpinejs';
import _ from 'lodash';

window.Alpine = Alpine;

// navigation
window.slideout = function () {
	return {
		open: false,
		usedKeyboard: false,
		init() {
			this.$watch('open', value => {
				value && this.$refs.closeButton.focus()
				this.toggleOverlay()
			})
			this.toggleOverlay()
		},
		toggleOverlay() {
			document.body.classList[this.open ? 'add' : 'remove']('h-screen', 'overflow-hidden')
		}
	}
}
Alpine.start();

// home slider
import 'slick-carousel';
$('.hero-slider').slick({
	dots: true,
	infinite: true,
	speed: 500,
	fade: !0,
	cssEase: 'linear',
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 8000,
	draggable: false,
	arrows: false,
});




function saveBillingInformation() {
	let $form = $('#billingAddressForm');
	let dataJson = {
		'action': $form.find('[name="action"]').val(),
	};

	$form.find('select, input').each(function () {
		let NAME = $(this).attr('name') ?? '';
		let VAL = $(this).val();
		dataJson[NAME] = VAL;
		if (NAME.includes('firstName')) {
			if (!VAL) {
				$('#paymentInformationForm [name="firstName"]').parent().show();
			}
			$('#paymentInformationForm [name="firstName"]').val(VAL);
		}
		if (NAME.includes('lastName')) {
			$('#paymentInformationForm [name="lastName"]').val(VAL)
		}
	});


	let instance = $form.parsley();
	if(instance.validate()){
		makeAjaxRequest(dataJson, function(){
			console.log('User Billing Information Updated');
		});
	}
}


//function updateCouponCode(event) {
//	event.preventDefault();
//
//	let $input = $('[name="couponCode"]');
//	let VAL = $input.val();
//	let SUCCESS_MESSAGE = $input.data('success');
//
//	let codeSuccess = false;
//	let dataJson = {
//		'action': 'commerce/cart/update-cart',
//		'couponCode': VAL,
//		'successMessage': SUCCESS_MESSAGE,
//	};
//
//	for (const i in window.DISCOUNT_CODES) {
//		if (window.DISCOUNT_CODES[i].code == VAL) {
//			codeSuccess = window.DISCOUNT_CODES[i];
//		}
//	}
//	
//	makeAjaxRequest(dataJson, function () {
//		updateCouponCodeFrontEnd(codeSuccess);
//	}, function () {
//		alert('Error applying the coupon code.');
//	});
//}
//
//function updateCouponCodeFrontEnd(codeSuccess) {
//	// regardless if the code is successful or not
//	if (codeSuccess) {
//		alert('Code was successfully applied!');
//	} else {
//		alert('Code was not successful.');
//	}
//
//	// we will update the front-end with the correct cart information
//	makeAjaxRequest({
//		'action': 'commerce/cart/get-cart'
//	}, function (response) {
//		let cart = response.responseJSON.cart;
//		if (cart.adjustmentSubtotal) {
//			$('.total-price-section .discount.item').removeClass('hidden');
//			$('.total-price-section .discount.item .price').text(cart.adjustmentSubtotalAsCurrency);
//		} else {
//			$('.total-price-section .discount.item').addClass('hidden');
//		}
//		$('.total-price-section .total.item .price').text(cart.itemTotalAsCurrency);
//
//		$('.line-item-overview > div .line-item').remove();
//		cart.lineItems.forEach(item => {
//			let $lineItem = $(`
//				<div class="line-item flex">
//					<div class="line-item-title w-3/5">
//						${item.snapshot.title}
//					</div>
//					<div class="line-item-price w-2/5 text-right">
//						${item.priceAsCurrency}
//					</div>
//				</div>
//			`);
//
//			item.adjustments.forEach(discount => {
//				$lineItem.find('.line-item-title').append(`<div class="discount text-xs">${discount.description}</div>`);
//				$lineItem.find('.line-item-price').append(`<div class="discount text-xs">${discount.amountAsCurrency}</div>`);
//			});
//
//			$('.line-item-overview > div').append($lineItem);
//		});
//	});
//}


function updateCartEmail() {
	let $form = $('#cartEmail');
	let options = {
		'action': $form.find('[name="action"]').val()
	};
	$form.find('select,input').each(function () {
		let NAME = $(this).attr('name') ?? '';
		let VAL = $(this).val();
		options[NAME] = VAL;
		if (NAME.includes('email')) {
			if (!window.billing_details) {
				window.billing_details = { email: VAL };
			} else {
				window.billing_details.email = VAL;
			}
		}
	});

	let instance = $form.parsley();
	if(instance.validate()){
		makeAjaxRequest(options, function () { 
			console.log('Cart email successfully updated!');
		});
	}
}


$('#billingAddressForm input').on('change', _.debounce(saveBillingInformation, 1000));
$('#cartEmail input').on('change', _.debounce(updateCartEmail, 100));
//$('#couponCodeForm').on('submit', updateCouponCode);


// ADD TO CART
$('.btn[data-variant-id]').on('click.add-to-cart', function () {
	let dataJson = {
		'action': 'commerce/cart/update-cart',
		'purchasableId': $(this).data('variant-id'),
	};

	dataJson[window.csrfTokenName] = window.csrfTokenValue;
	$.ajax({
		type: 'post',
		data: dataJson,
		complete: function (response) {
			console.log(response);
			if (response.status == 200 || response.status == 302) {
				$('.notice').addClass('active');
			}
			else {
				alert('There is an error. Please try again.');
				console.log(response.responseText);
			}
		}
	});
});

var closeNotice = document.querySelectorAll('notice-close');
if (closeNotice) {
	$( ".notice-close" ).on( "click", function() {
		$('.notice').removeClass('active');
	});
}


// Code to assist the contry / state dropdown selectors
var $countrySelects = document.querySelectorAll('select.js-address-country');
if ($countrySelects && $countrySelects.length) {
	$countrySelects.forEach(function (el) {
		el.addEventListener('change', function (ev) {
			var $this = ev.target;

			// get the value of the selected country.
			var cid = $this.value;
			var $closestAddressBox = $this.closest('.js-address-box');

			if ($closestAddressBox) {
				var $states = $closestAddressBox.querySelector('select.js-address-stateId');
				var $stateName = $closestAddressBox.querySelector('input.js-address-stateName');

				if ($states && $stateName) {
					var $options = $states.querySelectorAll('option');

					if ($options && $options.length) {
						$options.forEach(function (el) {
							el.remove();
						});
					}

					if (window.states.hasOwnProperty(cid)) {
						// We have states for this country, show the states drop down.
						$states.classList.remove('hidden');
						$states.setAttribute('name', $states.dataset.modelname + '[stateValue]');

						// We have states for this country, hide the stateName input.
						$stateName.removeAttribute('name');
						$stateName.classList.add('hidden');
						$stateName.value = '';

						// Add all states as options to drop down.
						for (var id in window.states[cid]) {
							var state = window.states[cid][id];
							var $option = document.createElement('OPTION');
							$option.text = state;
							$option.value = id;
							$states.appendChild($option);
						}

					} else {
						// hide the states dropdown, since this country has none.
						$states.classList.add('hidden');
						$states.removeAttribute('name');

						// show the stateName
						$stateName.classList.remove('hidden');
						$stateName.setAttribute('name', $stateName.dataset.modelname + '[stateValue]');
					}
				}
			}
		});
	});
}



// helper for payment source selector
var $paymentSources = document.querySelectorAll('[name="paymentSourceId"]');
var hasPaymentSources = $paymentSources && $paymentSources.length > 0;
var $gateways = document.querySelectorAll('[name="gatewayId"]');
var hasGateways = $gateways && $gateways.length > 0;

if (hasPaymentSources && hasGateways) {
	function deselectPaymentSources() {
		$paymentSources.forEach(function (el) {
			el.checked = false;
		});
	}

	function deselectGateways() {
		$gateways.forEach(function (el) {
			el.checked = false;
		});
	}

	$paymentSources.forEach(function (el) {
		el.addEventListener('change', function (ev) {
			deselectGateways();
		});
	});

	$gateways.forEach(function (el) {
		el.addEventListener('change', function (ev) {
			deselectPaymentSources();
		});
	});
}


let $paymentInformationForm = $('#paymentInformationForm');
let $payNowForm = $('#payNowForm');
let $paymentSourceSelect = $('#paymentSourceSelect');

function checkPaymentSource(event) {
	let VAL = $paymentSourceSelect.val();
	if (VAL == 'new' || VAL == undefined) {
		$paymentInformationForm.slideDown();
		$payNowForm.slideUp();
	} else {
		$paymentInformationForm.slideUp();
		$payNowForm.slideDown();

		makeAjaxRequest({
			'action': 'commerce/cart/update-cart',
			'paymentSourceId': VAL
		}, function (response) {
			console.log('Cart Payment Source Updated!');
		});
	}
}

$paymentSourceSelect.on('change', checkPaymentSource);
checkPaymentSource();


// event listener for payment form submission on checkout page

// $paymentInformationForm.on('submit', function (event) {
//     // event.preventDefault();
// 	let $paymentSourceSelect = $('#paymentSourceSelect');
// 	makeAjaxRequest({
// 		'action': 'commerce/cart/get-cart'
// 	}, function (response) {
// 		let cart = response.responseJSON.cart;
// 		console.log(cart.paymentSourceId);

// 		// there is already a set payment source
// 		// if(cart.paymentSourceId){
// 		// 	window.stripe_elements.cardCvcElement.unmount();
// 		// 	window.stripe_elements.cardNumberElement.unmount();
// 		// 	window.stripe_elements.cardExpiryElement.unmount();
// 		// 	// $paymentInformationForm.trigger('submit');
// 		// } else {
// 		// 	// we will create the new payment source in stripe
// 		// }
// 	});

// 	console.log($paymentInformationForm, $paymentSourceSelect);

//     window.stripe.createPaymentMethod({ 
// 		type: 'card', 
// 		card: window.stripe_elements.cardNumberElement, 
// 		billing_details: window.billing_details 
// 	}).then(function (result) {
//         if (result.error) {
//             // Show the user any errors
//             var errorElement = document.getElementById('card-errors');
//             errorElement.textContent = result.error.message;
//         } else {
//             var hiddenInput = document.createElement('input');
//             hiddenInput.setAttribute('type', 'hidden');
//             hiddenInput.setAttribute('name', 'paymentMethodId'); // Craft Commerce only needs this
//             hiddenInput.setAttribute('value', result.paymentMethod.id);
//             form.appendChild(hiddenInput);
//             form.submit();
//         }
//     });
// });




function makeAjaxRequest(options, successFunc, errorFunc) {
	let successFunction = successFunc ?? function () { };
	let errorFunction = errorFunc ?? function () { };
	options[window.csrfTokenName] = window.csrfTokenValue;
	$.ajax({
		type: 'post',
		data: options,
		dataType: 'json',
		complete: function (response) {
			if (response.status == 200 || response.status == 302) {
				successFunction(response);
			}
			else {
				errorFunction(response);
				console.log(response);
			}
		}
	});
}

